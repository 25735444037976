<div
  class="image-preview-component"
  [class.simple-mode]="simpleMode"
  (click)="simpleMode && onEditImage($event)"
>
  @if (selectedFile) {
    @if (isVideo) {
      <video
        class="video-preview"
        [src]="selectedFile.path || createDataUrl(selectedFile)"
        type="video/mp4"
        [muted]="'muted'"
      ></video>
      <i class="fa fa-play play-icon"></i>
    } @else {
      <img
        class="image-preview"
        [src]="selectedFile.path || createDataUrl(selectedFile)"
      />
    }
  }

  <!-- drag handle -->
  <ng-content></ng-content>

  <div class="image-preview-actions-cont">
    <div class="action-icon" (click)="onDeleteFile($event)">
      <i class="far fa-trash-alt"></i>
    </div>

    @if (!simpleMode || isVideo) {
      <div class="action-icon" (click)="onViewImage($event)">
        <i class="far fa-eye"></i>
      </div>

      <div class="action-icon" (click)="onEditImage($event)">
        <i class="far fa-pencil"></i>
      </div>
    }
  </div>
</div>
@if (dataEditable && selectedFile) {
  <div class="asset-info-cont">
    <mat-form-field appearance="outline" class="w-100">
      <mat-label>{{ "APP.UPLOADER.FILE_NAME" | translate }}*</mat-label>
      <input
        #seoPictureFileName
        type="text"
        matInput
        [ngModel]="selectedFile.name"
        (ngModelChange)="onAssetNameChanged($event)"
        maxlength="65"
        required
      />
      <span matTextSuffix
        >{{ seoPictureFileName.value.length || "" }} | 65</span
      >
      <div
        matSuffix
        class="icon-suffix"
        matTooltip="{{ 'APP.UPLOADER.FILE_NAME_INFO' | translate }}"
        matTooltipPosition="above"
      >
        <i class="fal fa-info-circle"></i>
      </div>
    </mat-form-field>
    <mat-form-field appearance="outline" class="w-100">
      <mat-label>{{
        "APP.DESIGN_TEMPLATES.CREATE.SEO_SHARING_PICTURE_TITLE" | translate
      }}</mat-label>
      <input
        #seoPictureTitle
        type="text"
        matInput
        [ngModel]="selectedFile.title"
        (ngModelChange)="onAssetTitleChanged($event)"
        maxlength="65"
      />
      <span matTextSuffix>{{ seoPictureTitle.value.length || "" }} | 65</span>
    </mat-form-field>

    <mat-form-field appearance="outline" class="w-100">
      <mat-label>{{
        "APP.DESIGN_TEMPLATES.CREATE.SEO_SHARING_PICTURE_ALT" | translate
      }}</mat-label>
      <input
        #seoPictureAlt
        type="text"
        matInput
        [ngModel]="selectedFile.alt"
        (ngModelChange)="onAssetAltChanged($event)"
        minlength="70"
        maxlength="100"
      />
      <span matTextSuffix>{{ seoPictureAlt.value.length || "" }} | 70-100</span>
      <div
        matSuffix
        class="icon-suffix"
        matTooltip="{{ 'APP.UPLOADER.ALT_INFO' | translate }}"
        matTooltipPosition="above"
      >
        <i class="fal fa-info-circle"></i>
      </div>
    </mat-form-field>
  </div>
}
