import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { Asset, createDataUrl, emptyAsset } from '@models/shared/asset.model';
import { NgxFileDropEntry, NgxFileDropModule } from 'ngx-file-drop';
import { TranslateModule } from '@ngx-translate/core';
import { ImageCropperComponent } from '../image-cropper/image-cropper.component';
import { ImagePreviewComponent } from '../image-preview/image-preview.component';
import {
  CdkDrag,
  CdkDragDrop,
  CdkDragHandle,
  CdkDropList,
  moveItemInArray,
} from '@angular/cdk/drag-drop';
import { MatDialog } from '@angular/material/dialog';

export enum LogoViewType {
  REGULAR = 'REGULAR',
  IMAGE_LOGO = 'IMAGE_LOGO',
  TEXT_LOGO = 'TEXT_LOGO',
  COVER_IMAGE = 'COVER_IMAGE',
}

export const IMAGE_MEDIA_TYPES = 'image/x-png,image/jpeg,image/gif';
export const PDF_MEDIA_TYPE = 'application/pdf';
export const IMAGE_AND_VIDEO_MEDIA_TYPES =
  'image/x-png,image/jpeg,image/gif,video/mp4,video/webm';
export const VIDEO_MEDIA_TYPES = 'video/mp4,	video/quicktime';
// tslint:disable
const DOCUMENT_MEDIA_TYPES =
  'application/pdf,application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/msword,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';

@Component({
  selector: 'app-file-uploader',
  standalone: true,
  imports: [
    CommonModule,
    NgxFileDropModule,
    TranslateModule,
    CdkDropList,
    CdkDrag,
    CdkDragHandle,
    ImageCropperComponent,
    ImagePreviewComponent,
  ],
  templateUrl: './file-uploader.component.html',
  styleUrl: './file-uploader.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FileUploaderComponent {
  createDataUrl = createDataUrl;

  private _selectedFile?: Asset;
  private _selectedFiles: Asset[] = [];

  @Input() set selectedFile(selectedFile: Asset | undefined) {
    this._selectedFile = selectedFile;
    if (!this._selectedFile) {
      this.fileBase64 = null;
    } else if (this._selectedFile.base64) {
      if (this._selectedFile.mimeType?.startsWith('video')) {
        this.fileBase64 =
          this.inlineVideoBase64Prefix + this._selectedFile.base64;
      } else {
        this.fileBase64 =
          this.inlineImageBase64Prefix + this._selectedFile.base64;
      }
    }

    if (this.selectedFile && this.useImagePreviewComponent) {
      this.editMode = false;
    }
  }

  get selectedFile(): Asset | undefined {
    return this._selectedFile;
  }

  @Input() set selectedFiles(selectedFiles: Asset[]) {
    this._selectedFiles = selectedFiles;

    if (!this._selectedFile) {
      this.fileBase64 = null;
    } else if (this._selectedFile.base64) {
      if (this._selectedFile.mimeType?.startsWith('video')) {
        this.fileBase64 =
          this.inlineVideoBase64Prefix + this._selectedFile.base64;
      } else {
        this.fileBase64 =
          this.inlineImageBase64Prefix + this._selectedFile.base64;
      }
    }
  }

  get selectedFiles(): Asset[] {
    return this._selectedFiles;
  }

  @Input() multiple = false;

  @Input() height?: number; // = 130;
  @Input() width?: number; // = 130;

  @Input() fluidWidth = false;

  @Input() aspectRatio: number = 1 / 1;

  @Input() resizeToWidth: number = 0;

  @Input() displayImageCropper?: boolean;

  @Input() borderRadius: 0 | 5 | 15 = 0;

  @Input() dataEditable = false;

  @Input() titleAction?: string;
  @Input() descriptionAction?: string;

  private _uploaderType: 'IMAGE' | 'DOCUMENT' | 'IMAGE_AND_VIDEO' | 'VIDEO' =
    'IMAGE';
  fileTypes = IMAGE_MEDIA_TYPES;

  @Input() set uploaderType(uploaderType) {
    this._uploaderType = uploaderType;
    this.setupFileTypes();
  }

  get uploaderType() {
    return this._uploaderType;
  }

  private _logoViewType: LogoViewType = LogoViewType.IMAGE_LOGO;

  @Input() set logoViewType(logoViewType: LogoViewType) {
    this._logoViewType = logoViewType;
    // if (this._logoViewType === LogoViewType.IMAGE_LOGO) {
    //   this.height = null;
    //   this.width = null;
    // }
  }

  get logoViewType() {
    return this._logoViewType;
  }

  @Input() coverImage: string | null = null;

  @Input() displayDeleteIcon = true;

  @Input() useImagePreviewComponent = false;

  @Input() simpleMode = false;

  @Input() invalid = false;

  fileBase64: string | null = null;

  showCropper = false;

  editMode = true;

  @Output() fileChanged: EventEmitter<Asset> = new EventEmitter();

  @Output() filesChanged: EventEmitter<Asset[]> = new EventEmitter();

  @Output() deleteFile: EventEmitter<void> = new EventEmitter();

  @ViewChild('fileInput') fileInput: any;

  private readonly base64Prefix = 'base64,';
  private readonly inlineImageBase64Prefix = 'data:image/png;base64,';
  private readonly inlineVideoBase64Prefix = 'data:video/mp4;base64,';

  constructor(
    private dialog: MatDialog,
    private changeDetectorRef: ChangeDetectorRef,
  ) {}

  fileChangeEvent(event: Event): void {
    const target = event.target as HTMLInputElement;
    const files = target.files as FileList;
    if (this.multiple) {
      for (let i = 0; i < files.length; i++) {
        const file = files.item(i);
        if (file) {
          this.handleFileChangeMultiple(file);
        }
      }
    } else {
      this.handleFileChange(files[0]);
    }
  }

  clearFile() {
    this.selectedFile = undefined;
    this.changeDetectorRef.detectChanges();
  }

  private handleFileChange(file: File) {
    if (!file) {
      this.clearFile();
      this.fileChanged.emit(this.selectedFile);
      this.editMode = false;
      return;
    }

    this.selectedFile = emptyAsset();

    this.selectedFile.name = file ? file.name : '';

    if (file && file.type) {
      this.selectedFile.mimeType = file.type;
    }

    this.loadImage(file);
  }

  private loadImage(file: File) {
    this.getBase64(file).then((base64: string | ArrayBuffer | null) => {
      if (!base64 || !this.selectedFile) {
        return;
      }

      base64 = base64.toString();

      this.fileBase64 = base64;
      base64 = base64.substring(
        base64.indexOf(this.base64Prefix) + this.base64Prefix.length,
      );

      this.selectedFile.base64 = base64;

      this.fileChanged.emit(this.selectedFile);
      this.showCropper = false;
      this.editMode = false;
    });
  }

  dropped(files: NgxFileDropEntry[]) {
    if (files.length > 0) {
      if (this.multiple) {
        files.forEach((droppedFile) => {
          if (droppedFile.fileEntry.isFile) {
            const fileEntry = droppedFile.fileEntry as FileSystemFileEntry;
            fileEntry.file((file: File) => this.handleFileChangeMultiple(file));
          }
        });
      } else {
        const droppedFile = files[0];
        if (droppedFile.fileEntry.isFile) {
          const fileEntry = droppedFile.fileEntry as FileSystemFileEntry;
          fileEntry.file((file: File) => this.handleFileChange(file));
        }
      }
    }
  }

  imageCropped(imageBase64: string) {
    if (imageBase64 !== '') {
      this.fileBase64 = imageBase64;
      if (this.fileBase64 && this.selectedFile) {
        const base64 = this.fileBase64.substring(
          this.fileBase64.indexOf(this.base64Prefix) + this.base64Prefix.length,
        );
        this.selectedFile.base64 = base64;
        this.fileChanged.emit(this.selectedFile);
        this.editMode = false;
      }
    }

    this.cancelCropper();
  }

  cancelCropper() {
    this.showCropper = false;
  }

  get hasImage(): boolean {
    return ((this.uploaderType === 'IMAGE' ||
      this.uploaderType === 'IMAGE_AND_VIDEO') &&
      (this.fileBase64 != null ||
        (this.selectedFile &&
          this.selectedFile.path != null &&
          this.selectedFile.path !== '')) &&
      this.selectedFile?.mimeType?.startsWith('image'))!;
  }

  get hasVideo(): boolean {
    return ((this.uploaderType === 'IMAGE_AND_VIDEO' ||
      this.uploaderType === 'VIDEO') &&
      (this.fileBase64 != null ||
        (this.selectedFile &&
          this.selectedFile.path != null &&
          this.selectedFile.path !== '')) &&
      this.selectedFile?.mimeType?.startsWith('video'))!;
  }

  get hasDocument(): boolean {
    return (this.uploaderType === 'DOCUMENT' &&
      (this.fileBase64 != null ||
        (this.selectedFile &&
          (this.selectedFile.base64 !== null ||
            (this.selectedFile.path != null &&
              this.selectedFile.path !== '')))))!;
  }

  onDeleteFile(e?: Event) {
    if (e) e.preventDefault();

    this.clearFile();
    this.fileChanged.emit(this.selectedFile);
    this.deleteFile.emit();
  }

  onCropImage(e: Event) {
    e.preventDefault();
    this.showCropper = true;
  }

  onEditImage() {
    if (this.simpleMode) {
      this.fileInput?.nativeElement.click();
    } else {
      this.editMode = true;
    }
  }

  private getBase64(file: File) {
    return new Promise<string | ArrayBuffer | null>((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  }

  private setupFileTypes() {
    if (this.uploaderType === 'IMAGE') {
      this.fileTypes = IMAGE_MEDIA_TYPES;
    } else if (this.uploaderType === 'IMAGE_AND_VIDEO') {
      this.fileTypes = IMAGE_AND_VIDEO_MEDIA_TYPES;
    } else if (this.uploaderType === 'VIDEO') {
      this.fileTypes = VIDEO_MEDIA_TYPES;
    } else {
      this.fileTypes = DOCUMENT_MEDIA_TYPES;
    }
  }

  // multiple
  private handleFileChangeMultiple(file: File) {
    if (!file) {
      return;
    }
    const selectedFile = emptyAsset();
    selectedFile.name = file ? file.name : '';
    if (file && file.type) {
      selectedFile.mimeType = file.type;
    }

    this.loadImageMultiple(file, selectedFile);
  }

  private loadImageMultiple(file: File, selectedFile: Asset) {
    this.getBase64(file).then((base64: string | ArrayBuffer | null) => {
      if (!base64 || !selectedFile) {
        return;
      }

      base64 = base64.toString();
      base64 = base64.substring(
        base64.indexOf(this.base64Prefix) + this.base64Prefix.length,
      );

      selectedFile.base64 = base64;

      this.selectedFiles.push(selectedFile);

      this.filesChanged.emit(this.selectedFiles);
    });
  }

  dragDropped(event: CdkDragDrop<string[]>) {
    moveItemInArray(
      this.selectedFiles,
      event.previousIndex,
      event.currentIndex,
    );
    this.filesChanged.emit(this.selectedFiles);
  }

  onUpdateFileMultiple(file: Asset): void {
    const index = this.selectedFiles.findIndex(
      (selectedFile) =>
        (selectedFile.base64 &&
          selectedFile.base64 !== '' &&
          selectedFile.base64 === file.base64) ||
        (selectedFile.path &&
          selectedFile.path !== '' &&
          selectedFile.path === file.path),
    );

    if (index >= 0) {
      this.selectedFiles[index] = file;
    }
  }

  onDeleteFileMultiple(file: Asset): void {
    const index = this.selectedFiles.findIndex(
      (selectedFile) => selectedFile === file,
    );

    if (index >= 0) {
      this.selectedFiles.splice(index, 1);
    }
  }

  async onEditImageMultiple(file: Asset) {
    const index = this.selectedFiles.findIndex(
      (selectedFile) => selectedFile === file,
    );

    if (index >= 0) {
      const { FileUploaderDialog } = await import(
        '@modules/shared/dialogs/file-uploader/file-uploader.dialog'
      );
      const dialogRef = this.dialog.open(FileUploaderDialog, {
        maxWidth: '654px',
        width: '100%',
        height: 'auto',
        maxHeight: '100vh',
        data: {
          file: file,
          height: this.height,
          width: this.width,
          fluidWidth: this.fluidWidth,
          aspectRatio: this.aspectRatio,
          resizeToWidth: this.resizeToWidth,
          borderRadius: this.borderRadius,
          uploaderType: this.uploaderType,
          logoViewType: this.logoViewType,
          coverImage: this.coverImage,
          displayDeleteIcon: this.displayDeleteIcon,
        },
        panelClass: 'viewer-dialog',
      });
      dialogRef.afterClosed().subscribe((result) => {
        if (result) {
          if (result.asset) {
            this.selectedFiles[index] = result.asset;
          } else {
            this.selectedFiles.splice(index, 1);
          }
          this.changeDetectorRef.detectChanges();
        }
      });
    }
  }
}
